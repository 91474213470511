import { isDefined } from "@clipboard-health/util-ts";
import { formatISO, parseISO } from "date-fns";

import { type OpenShift } from "../Open/types";
import { type OpenShiftDatesResponse } from "../Open/usePaginatedOpenShiftDates";
import { type OpenShiftDatesResponseWithUrgent } from "./types";

export function mergeUrgentShiftsWithCountData(
  countData: OpenShiftDatesResponse,
  urgentShifts: OpenShift[]
): OpenShiftDatesResponseWithUrgent {
  const result: OpenShiftDatesResponseWithUrgent = Object.fromEntries(
    Object.entries(countData).map(([date, day]) => {
      return [
        date,
        {
          ...day,
          urgentShifts: [],
        },
      ];
    })
  );

  urgentShifts.forEach((shift) => {
    const { start, timeSlot } = shift.attributes;

    const shiftDate = formatISO(parseISO(start), { representation: "date" });

    if (isDefined(result[shiftDate])) {
      result[shiftDate] = {
        ...result[shiftDate],
        urgentShifts: [...(result[shiftDate].urgentShifts ?? []), timeSlot],
      };
    }
  });

  return result;
}
