import { CbhIcon } from "@clipboard-health/ui-components";

interface OpenShiftCalendarCellIndicatorProps {
  isUrgent?: boolean;
  isPriority?: boolean;
}

export function OpenShiftCalendarCellIndicator(props: OpenShiftCalendarCellIndicatorProps) {
  const { isUrgent, isPriority } = props;

  if (isUrgent) {
    return (
      <CbhIcon
        size="small"
        type="clock-filled"
        sx={(theme) => ({ color: theme.palette.shift?.urgent.border })}
      />
    );
  }

  if (isPriority) {
    return (
      <CbhIcon
        size="small"
        type="priority"
        sx={(theme) => ({ color: theme.palette.shift?.priority.icon })}
      />
    );
  }

  return <CbhIcon size="small" type="dot" sx={(theme) => ({ color: theme.palette.info.main })} />;
}
