import { Card } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Skeleton, Stack, useTheme } from "@mui/material";
import { type ReactNode } from "react";

export interface ShiftWithContentCardSkeletonProps {
  children?: ReactNode;
  pillsSkeleton?: ReactNode;
  loadingLabel?: string;
}

export function ShiftWithContentCardSkeleton(props: ShiftWithContentCardSkeletonProps) {
  const { children, pillsSkeleton, loadingLabel } = props;
  const theme = useTheme();

  return (
    <Card sx={{ flexGrow: 1 }} role="progressbar" aria-label={loadingLabel}>
      <CardContent sx={{ padding: theme.spacing(6) }}>
        <Stack spacing={6} direction="column">
          {isDefined(pillsSkeleton) && (
            <Stack direction="row" spacing={2}>
              {pillsSkeleton}
            </Stack>
          )}

          <Stack direction="row" spacing={4} alignItems="flex-start">
            <Skeleton variant="rounded" width={48} height={48} />
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <Skeleton variant="text" width="40%" height="1.25rem" />
              <Skeleton variant="text" width="60%" height="1.25rem" />
              <Skeleton variant="text" width="40%" height="1.25rem" />
              <Skeleton variant="text" width="75%" height="1.25rem" />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            {children ?? (
              <>
                <Skeleton variant="text" width="90%" height="1.5rem" />
                <Skeleton variant="text" width="80%" height="1.5rem" />
                <Skeleton variant="text" width="70%" height="1.5rem" />
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
