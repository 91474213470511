import { Stack } from "@mui/material";

import { BookedShiftsIconRow } from "../../Calendar/BookedShiftsIconRow";
import { type TimeSlot } from "../Open/types";
import { OpenShiftCalendarCellIndicator } from "./OpenShiftIndicator";

interface ShiftCalendarCellAvailabilityInfoProps {
  bookedShiftTimeSlots: TimeSlot[];
  isDateAvailable?: boolean;
  hasUrgentShift?: boolean;
  hasPriorityShift?: boolean;
  disabled?: boolean;
}

/**
 * Renders icons representing open shifts and booked shifts.
 * If the date is available, it will always show the open shift icon (indicating urgent / priority too)
 * If there are multiple booked shifts, it will show the badge with the number of booked shifts
 */
export function ShiftCalendarCellAvailabilityInfo(props: ShiftCalendarCellAvailabilityInfoProps) {
  const { bookedShiftTimeSlots, isDateAvailable, disabled, hasUrgentShift, hasPriorityShift } =
    props;

  return (
    <Stack direction="row" alignItems="center" spacing="0.125rem" sx={{ height: "1rem" }}>
      <BookedShiftsIconRow
        disabled={disabled}
        maxShiftIconsCountToShow={isDateAvailable ? 1 : 2}
        bookedShiftTimeSlots={bookedShiftTimeSlots}
      />

      {isDateAvailable && (
        <OpenShiftCalendarCellIndicator isUrgent={hasUrgentShift} isPriority={hasPriorityShift} />
      )}
    </Stack>
  );
}
