import { mergeSxProps } from "@clipboard-health/ui-react";
import { CardContent, type CardContentProps } from "@mui/material";

/**
 * CardContent for shift cards specifically.
 * Uses space-between layout, stretching to fill height with predefined gap.
 */
export function BookingCardContentWrapper(props: CardContentProps) {
  const { sx, ...restProps } = props;

  return (
    <CardContent
      sx={mergeSxProps(
        {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 6,
          height: "100%",
        },
        sx
      )}
      {...restProps}
    />
  );
}
