import { Stack } from "@mui/material";

import { PayInfoLabel } from "../../Pay/PayInfoLabel";
import { ShiftQualificationPill } from "../QualificationPill";

interface WorkerShiftCardFooterProps {
  hourlyPay?: string;
  totalPay?: string;
  qualificationText: string;
  isLoadingQualification?: boolean;
}

/**
 * Renders qualification pill and pay info label
 */
export function WorkerShiftCardFooter(props: WorkerShiftCardFooterProps) {
  const {
    hourlyPay,
    totalPay,
    qualificationText: qualificationName,
    isLoadingQualification,
  } = props;

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <ShiftQualificationPill
        qualificationText={qualificationName}
        isLoading={isLoadingQualification}
      />

      <PayInfoLabel isLoading={false} hourlyPay={hourlyPay} totalPay={totalPay} />
    </Stack>
  );
}
