import { addMonths, endOfMonth, startOfDay } from "date-fns";

/**
 * This function takes a start date and returns a date range spanning from that date to the end of 1 month later.
 * Used by the calendar picker to determine the visible date range. When loading more shifts, this function provides
 * the next date range to fetch.
 *
 * Example: If startDate is February 15th, the range will be February 15th to March 31st
 */
export function getCalendarPickerDateRange(startDate: Date) {
  return {
    startDate: startOfDay(startDate),
    endDate: endOfMonth(addMonths(startDate, 1)),
  };
}
