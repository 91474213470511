import { Card, type CardProps } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";

export function ShiftCardWrapper(props: CardProps) {
  const { variant = "quaternary", outlined = true, sx, ...restProps } = props;

  return (
    <Card
      variant={variant}
      outlined={outlined}
      sx={mergeSxProps(
        (theme) => ({
          minHeight: theme.size?.shiftCard.minHeight,
        }),
        sx
      )}
      {...restProps}
    />
  );
}
