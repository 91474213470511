import { mergeSxProps } from "@clipboard-health/ui-react";
import { type ForwardedRef, forwardRef } from "react";

import { CalendarCell, type CalendarCellProps } from "../../Calendar/CalendarCell";
import { type TimeSlot } from "../Open/types";
import { ShiftCalendarCellAvailabilityInfo } from "./ShiftCalendarCellAvailabilityInfo";

export interface ShiftCalendarCellProps extends CalendarCellProps {
  isDateAvailable: boolean;
  bookedShiftTimeSlots: TimeSlot[];
  hasUrgentShift?: boolean;
  hasPriorityShift?: boolean;
  disabled?: boolean;
}

function BaseShiftCalendarCell(
  props: ShiftCalendarCellProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    isDateAvailable,
    bookedShiftTimeSlots,
    hasUrgentShift,
    hasPriorityShift,
    disabled,
    sx,
    ...restProps
  } = props;

  const hasBookedShifts = bookedShiftTimeSlots.length > 0;

  return (
    <CalendarCell
      ref={ref}
      disabled={disabled}
      sx={mergeSxProps(
        (theme) => ({
          backgroundColor:
            !disabled && hasBookedShifts
              ? theme.palette.intent?.success.background
              : theme.palette.background.tertiary,
        }),
        sx
      )}
      {...restProps}
    >
      <ShiftCalendarCellAvailabilityInfo
        bookedShiftTimeSlots={bookedShiftTimeSlots}
        isDateAvailable={isDateAvailable}
        hasUrgentShift={hasUrgentShift}
        hasPriorityShift={hasPriorityShift}
      />
    </CalendarCell>
  );
}

export const ShiftCalendarCell = forwardRef(BaseShiftCalendarCell);
