import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";
import { usePaginatedWorkerShifts } from "@src/appV2/OpenShifts/api/usePaginatedWorkerShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { differenceInMonths, minutesToMilliseconds, startOfMonth } from "date-fns";
import { useState } from "react";

import { filterOpenShiftsCount } from "../../ShiftDiscovery/Filters/filterOpenShiftsCount";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { groupShiftsByDateAndTimeSlot } from "../../utils/groupShiftsByDateAndTimeSlot";
import { useGetOpenShiftsForDates } from "../Open/useGetOpenShiftsForDates";
import {
  type OpenShiftDatesResponse,
  usePaginatedOpenShiftDates,
} from "../Open/usePaginatedOpenShiftDates";
import { getCalendarPickerDateRange } from "./getCalendarPickerDateRange";
import { mergeUrgentShiftsWithCountData } from "./mergeUrgentShiftsWithCountData";
import { type OpenShiftDatesResponseWithUrgent } from "./types";

interface UseShiftDiscoveryCalendarDataProps {
  initialDateRange: DateRange;
}

export const useShiftDiscoveryCalendarData = (props: UseShiftDiscoveryCalendarDataProps) => {
  const { initialDateRange } = props;

  const worker = useDefinedWorker();
  const workerGeoLocation = useWorkerGeoLocation();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { distance, shiftTimeSlots, licenses } = useShiftDiscoveryUserFiltersContext();

  /**
   * This is a total date range used within the consumer of this hook.
   * If `loadMore` is called, this date range will be extended to the next end range.
   */
  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);

  const {
    data: workerShiftsData,
    isSuccess: workerShiftsIsSuccess,
    isLoading: workerShiftsIsLoading,
    refetch: refetchWorkerShifts,
    fetchNextPage: fetchMoreWorkerShifts,
    isFetchingNextPage: workerShiftsIsFetchingNextPage,
  } = usePaginatedWorkerShifts({
    initialDateRange: {
      startDate: startOfMonth(initialDateRange.startDate),
      endDate: initialDateRange.endDate,
    },
    groupByDate: false,
    tmz: timezone ?? "",
  });

  const workerShiftsByDate = workerShiftsIsSuccess
    ? groupShiftsByDateAndTimeSlot(
        workerShiftsData.pages.flatMap((page) => page.agentShifts) ?? [],
        timezone
      )
    : {};

  const {
    data: paginatedOpenShiftsCountData,
    isLoading: openShiftsCountIsLoading,
    refetch: refetchOpenShiftsCount,
    fetchNextPage: fetchMoreOpenShiftsCount,
    isFetchingNextPage: openShiftsCountIsFetchingNextPage,
  } = usePaginatedOpenShiftDates(
    {
      initialDateRange,
      filter: {
        timezone,
        area: {
          latitude: workerGeoLocation?.latitude ?? 0,
          longitude: workerGeoLocation?.longitude ?? 0,
          radiusInMiles: distance,
        },
        qualifications: licenses,
      },
    },
    {
      enabled: isDefined(timezone) && isDefined(worker?.preference),
      staleTime: minutesToMilliseconds(1),
    }
  );

  /**
   * The above call for usePaginatedOpenShiftCount does not support fetching urgent shifts, so we need to fetch them separately.
   * These open shifts are used to determine if a day has urgent shifts by merging them with the open shifts count data.
   * See `mergeUrgentShiftsWithCountData` for more details.
   */
  const {
    shifts: urgentShifts,
    pagination: urgentShiftsPagination,
    isLoading: urgentShiftsIsLoading,
  } = useGetOpenShiftsForDates({
    enabled: isDefined(workerGeoLocation),
    dates: [dateRange.startDate, dateRange.endDate],
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      isUrgent: true,
      area: {
        latitude: workerGeoLocation?.latitude ?? 0,
        longitude: workerGeoLocation?.longitude ?? 0,
        radiusInMiles: distance,
      },
    },
  });

  const unfilteredOpenShiftsCountData =
    paginatedOpenShiftsCountData?.pages.reduce<OpenShiftDatesResponse>((result, page) => {
      return {
        ...result,
        ...page.data,
      };
    }, {}) ?? {};

  const unfilteredOpenShiftsCountDataWithUrgency = mergeUrgentShiftsWithCountData(
    unfilteredOpenShiftsCountData,
    urgentShifts
  );

  const filteredOpenShiftsCountData: OpenShiftDatesResponseWithUrgent = filterOpenShiftsCount(
    unfilteredOpenShiftsCountDataWithUrgency,
    {
      shiftTimeSlots,
    }
  );

  const loadMore = async () => {
    const nextDateRangeToFetch = getCalendarPickerDateRange(dateRange.endDate);

    await Promise.all([
      fetchMoreWorkerShifts({
        pageParam: nextDateRangeToFetch,
      }),

      fetchMoreOpenShiftsCount({
        pageParam: nextDateRangeToFetch,
      }),

      urgentShiftsPagination?.fetchNextPage({
        pageParam: nextDateRangeToFetch,
      }),
    ]);

    setDateRange({
      startDate: dateRange.startDate,
      endDate: nextDateRangeToFetch.endDate,
    });
  };

  const refetch = () => {
    void refetchWorkerShifts();
    void refetchOpenShiftsCount();
  };

  return {
    dateRange,
    setDateRange,
    workerShiftsByDate,
    workerShiftsIsLoading,
    workerShiftsIsFetchingNextPage,
    unfilteredOpenShiftsCountData: unfilteredOpenShiftsCountDataWithUrgency,
    filteredOpenShiftsCountData,
    openShiftsCountIsLoading,
    openShiftsCountIsFetchingNextPage,
    urgentShifts,
    urgentShiftsIsLoading,
    refetch,
    loadMore,
    canLoadMore:
      isDefined(dateRange.endDate) &&
      differenceInMonths(dateRange.endDate, dateRange.startDate) < 12,
  };
};
