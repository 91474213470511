import type { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { DotSeparatedList, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useGetQualificationDisplayLabel } from "@src/appV2/Qualifications/api/useGetQualificationDisplayLabel";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { BookingCardContentWrapper } from "../CardContentWrapper";
import { ShiftType } from "../constants";
import { ShiftCardSummaryInfo } from "../Open/CardSummaryInfo";
import { ShiftCardPillsWrapper } from "../PillsWrapper";
import { BookingCardSummaryWrapper } from "../SummaryWrapper";
import { TimeSlotIndicator } from "../TimeSlotIndicator";
import { UrgentShiftPill } from "../Urgent/Pill";
import { WorkerShiftCardFooter } from "./Footer";
import { ShiftCardWrapper } from "./Wrapper";

interface MyShiftCardProps {
  shiftTimeSlot: TimeSlot;
  shiftStart: string;
  shiftEnd: string;
  shiftType?: ShiftType;
  shiftQualificationName: string;
  workplaceName?: string;
  workplaceTimezone?: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftWorkDurationInHours: number;
  outlined?: boolean;
}

export function MyShiftCard(props: MyShiftCardProps) {
  const {
    outlined,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftQualificationName,
    shiftType,
    shiftHourlyPay,
    shiftTotalPay,
    shiftWorkDurationInHours,
    workplaceName,
    workplaceTimezone,
  } = props;

  const startDate = parseISO(shiftStart);

  const { data: qualificationText, isLoading: isLoadingQualification } =
    useGetQualificationDisplayLabel(shiftQualificationName);

  return (
    <ShiftCardWrapper sx={{ width: "100%", minHeight: 0 }} outlined={outlined}>
      <BookingCardContentWrapper>
        <ShiftCardPillsWrapper>
          {shiftType === ShiftType.URGENT && <UrgentShiftPill />}

          <Pill
            size="small"
            sx={{ width: "fit-content" }}
            label={
              <DotSeparatedList>
                <Text semibold variant="body2">
                  {formatDayOfMonthWithDayName(startDate)}
                </Text>
                <Text semibold variant="body2">
                  Starts in {formatDistanceToNowStrict(startDate)}
                </Text>
              </DotSeparatedList>
            }
          />
        </ShiftCardPillsWrapper>

        <Stack spacing={6}>
          <BookingCardSummaryWrapper>
            <TimeSlotIndicator timeSlot={shiftTimeSlot} />

            <ShiftCardSummaryInfo
              dateRange={{ startDate: parseISO(shiftStart), endDate: parseISO(shiftEnd) }}
              timezone={workplaceTimezone}
              deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
              durationInHours={shiftWorkDurationInHours}
              workplaceName={String(workplaceName)}
            />
          </BookingCardSummaryWrapper>

          <WorkerShiftCardFooter
            hourlyPay={shiftHourlyPay}
            totalPay={shiftTotalPay}
            qualificationText={qualificationText}
            isLoadingQualification={isLoadingQualification}
          />
        </Stack>
      </BookingCardContentWrapper>
    </ShiftCardWrapper>
  );
}
