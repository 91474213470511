import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Card } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { parseISO } from "date-fns";
import { type ReactNode } from "react";

import { BookingCardContentWrapper } from "../CardContentWrapper";
import { ShiftCardSummaryInfo } from "../Open/CardSummaryInfo";
import { ShiftCardPillsWrapper } from "../PillsWrapper";
import { BookingCardSummaryWrapper } from "../SummaryWrapper";
import { TimeSlotIndicator } from "../TimeSlotIndicator";
import { WorkerShiftCardFooter } from "./Footer";

export interface ShiftWithContentCardProps {
  pills?: ReactNode[];
  children: ReactNode;
  shiftTimeSlot: TimeSlot;
  shiftStart: string;
  shiftEnd: string;
  shiftQualificationName: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftWorkDurationInHours?: number;

  workplaceName: string;
  workplaceTimezone: string;
}

export function ShiftWithContentCard(props: ShiftWithContentCardProps) {
  const {
    pills,
    children,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftQualificationName,
    shiftHourlyPay,
    shiftTotalPay,
    shiftWorkDurationInHours,
    workplaceName,
    workplaceTimezone,
  } = props;

  return (
    <Card sx={{ flexGrow: 1 }}>
      <BookingCardContentWrapper>
        <BookingCardSummaryWrapper>
          <TimeSlotIndicator timeSlot={shiftTimeSlot} />

          <ShiftCardSummaryInfo
            dateRange={{ startDate: parseISO(shiftStart), endDate: parseISO(shiftEnd) }}
            timezone={workplaceTimezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
            durationInHours={shiftWorkDurationInHours}
            workplaceName={workplaceName}
          >
            {isDefined(pills) && <ShiftCardPillsWrapper>{pills}</ShiftCardPillsWrapper>}
          </ShiftCardSummaryInfo>
        </BookingCardSummaryWrapper>

        <WorkerShiftCardFooter
          hourlyPay={shiftHourlyPay}
          totalPay={shiftTotalPay}
          // TODO: Integrate with useGetQualificationDisplayLabel
          qualificationText={shiftQualificationName}
          isLoadingQualification={false}
        />

        {children}
      </BookingCardContentWrapper>
    </Card>
  );
}
