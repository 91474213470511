/* eslint-disable import/max-dependencies */
import { LoadMoreTrigger } from "@clipboard-health/ui-components";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs/FullScreen/Dialog";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { eachMonthOfInterval, formatISO, isToday, max, parseISO, startOfMonth } from "date-fns";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";

import { CalendarGridMonth } from "../../Calendar/GridMonth";
import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { TextButton } from "../../components/TextButton";
import { useFiltersForm } from "../../ShiftDiscovery/Filters/BottomSheet/useFiltersForm";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { getBookedShiftTimeSlotsFromWorkerDayShifts } from "../../utils/getBookedShiftTimeSlotsFromWorkerDayShifts";
import { TimeSlot } from "../Open/types";
import { getCalendarPickerDateRange } from "./getCalendarPickerDateRange";
import { ShiftCalendarCell } from "./ShiftCalendarCell";
import { ShiftDatePickerHeader } from "./ShiftDatePickerHeader";
import { useShiftDiscoveryCalendarData } from "./useShiftDiscoveryCalendarData";

interface ShiftDatePickerProps {
  modalState: UseModalState;
}

export function ShiftDatePicker(props: ShiftDatePickerProps) {
  const { modalState } = props;
  const {
    setDates: setFiltersDates,
    dates,
    setShiftTimeSlots,
    shiftTimeSlots,
  } = useShiftDiscoveryUserFiltersContext();

  const {
    workerShiftsByDate,
    filteredOpenShiftsCountData,
    dateRange,
    openShiftsCountIsLoading,
    workerShiftsIsLoading,
    loadMore,
    canLoadMore,
    workerShiftsIsFetchingNextPage,
    openShiftsCountIsFetchingNextPage,
  } = useShiftDiscoveryCalendarData({
    initialDateRange: getCalendarPickerDateRange(new Date()),
  });

  const { form } = useFiltersForm();

  const isLoading = openShiftsCountIsLoading || workerShiftsIsLoading;

  const [selectedDates, setSelectedDates] = useState<string[]>(dates);

  const { startDate, endDate } = dateRange;

  const monthDates = eachMonthOfInterval({ start: startDate, end: endDate }).map((monthDate) =>
    max([startDate, startOfMonth(monthDate)])
  );

  const datesWithinSelection =
    selectedDates.length > 0 ? selectedDates : Object.keys(filteredOpenShiftsCountData);

  const totalOpenShiftsCount = datesWithinSelection.reduce(
    (total, date) => total + (filteredOpenShiftsCountData?.[date]?.total ?? 0),
    0
  );

  useEffect(() => {
    if (modalState.modalIsClosed) {
      setSelectedDates([]);
    }
  }, [modalState.modalIsClosed]);

  return (
    <FullScreenDialog
      hideBackdrop
      skipTopSafeAreaPadding
      modalState={modalState}
      variant="tertiary"
    >
      <ShiftDatePickerHeader form={form} />

      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Stack sx={{ px: 5, py: 7, overflow: "auto" }} gap={7}>
          {monthDates.map((monthDate) => (
            <CalendarGridMonth
              key={monthDate.toISOString()}
              startDate={monthDate}
              renderDateCell={(date) => {
                const isDateToday = isToday(date);
                const formattedDate = formatISO(date, { representation: "date" });
                const dateOpenShifts = filteredOpenShiftsCountData?.[formattedDate];
                const isDateAvailable = isDefined(dateOpenShifts);
                const hasPriorityShift =
                  dateOpenShifts?.priorityAccess.length > 0 ||
                  Object.keys(dateOpenShifts?.windows ?? {}).includes(ShiftWindow.A_TEAM);

                const hasUrgentShift = dateOpenShifts?.urgentShifts.length > 0;
                const workerDayShifts = workerShiftsByDate?.[formattedDate];
                const bookedShiftTimeSlots =
                  getBookedShiftTimeSlotsFromWorkerDayShifts(workerDayShifts);

                return (
                  <ShiftCalendarCell
                    date={date}
                    isToday={isDateToday}
                    isLoading={isLoading}
                    isDateAvailable={isDateAvailable}
                    hasPriorityShift={hasPriorityShift}
                    hasUrgentShift={hasUrgentShift}
                    isSelected={selectedDates.includes(formattedDate)}
                    bookedShiftTimeSlots={bookedShiftTimeSlots}
                    onToggleSelect={(isSelected) => {
                      setSelectedDates((previousSelectedDates) => {
                        if (isSelected) {
                          return [...previousSelectedDates, formattedDate];
                        }

                        return previousSelectedDates.filter((date) => date !== formattedDate);
                      });
                    }}
                  />
                );
              }}
              renderPastCell={(date) => {
                const formattedDate = formatISO(date, { representation: "date" });

                const workerDayShifts = workerShiftsByDate?.[formattedDate];
                const bookedShiftTimeSlots =
                  getBookedShiftTimeSlotsFromWorkerDayShifts(workerDayShifts);

                return (
                  <ShiftCalendarCell
                    disabled
                    date={date}
                    isDateAvailable={false}
                    bookedShiftTimeSlots={bookedShiftTimeSlots}
                  />
                );
              }}
            />
          ))}

          <LoadMoreTrigger
            canLoadMore={canLoadMore}
            isLoading={openShiftsCountIsFetchingNextPage || workerShiftsIsFetchingNextPage}
            renderEmptyState={() => (
              <Text color={(theme) => theme.palette.text.secondary} sx={{ textAlign: "center" }}>
                We admire your enthusiasm for planning ahead, but shifts beyond 1 year from now are
                out of reach. Please check back closer to the date!
              </Text>
            )}
            onLoadMore={loadMore}
          />
        </Stack>

        <DialogFooter
          withShadow
          orientation="horizontal"
          closeButtonAriaLabel="Close Shift Date Picker"
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_CLOSED);
            modalState.closeModal();
          }}
        >
          {(selectedDates.length > 0 || shiftTimeSlots.length > 0) && (
            <TextButton
              size="large"
              sx={{ marginLeft: 5, marginRight: "auto" }}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_CLEARED, {
                  trigger: "Calendar",
                });
                form.reset();
                setShiftTimeSlots([]);
                setSelectedDates([]);
                setFiltersDates([]);
              }}
            >
              Clear all
            </TextButton>
          )}

          <Button
            variant="contained"
            size="large"
            disabled={totalOpenShiftsCount === 0}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_VIEWED_SELECTED, {
                selectedDates,
              });

              const formValues = form.getValues();

              logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_APPLIED, {
                trigger: "Calendar",
                shiftCount: totalOpenShiftsCount,
                selectedDatesAll: selectedDates.length === 0,
                selectedDates,
                timeSlotsAll: formValues.shiftTimeSlots.length === 0,
                timeSlots:
                  formValues.shiftTimeSlots.length === 0
                    ? [TimeSlot.AM, TimeSlot.PM, TimeSlot.NOC]
                    : formValues.shiftTimeSlots,
              });

              if (selectedDates.length === 0) {
                modalState.closeModal();
                return;
              }

              setFiltersDates(sortBy(selectedDates, (dateString) => parseISO(dateString)));

              modalState.closeModal();
            }}
          >
            {totalOpenShiftsCount > 0
              ? `View ${totalOpenShiftsCount} open shifts`
              : "No shifts available"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </FullScreenDialog>
  );
}
/* eslint-enable import/max-dependencies */
